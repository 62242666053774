/* 1712px screen */
@media (max-width: 107em) {
  .zalo-widget,
  .phone-widget {
    width: 5rem;
  }

  .widget-cantact-number {
    display: none !important;
  }
}

/* 1344 small desktop */
@media (max-width: 84em) {
  .hero {
    max-width: 120rem;
  }

  .heading-primary {
    font-size: 4.4rem;
  }

  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }

  .product-img-container {
    width: 34rem;
    height: 34rem;
  }
}

/* 1264 px tablet */

@media (max-width: 79em) {
  html {
    font-size: 56.25%;
  }

  .grid {
    column-gap: 4.8rem;
    row-gap: 6.4rem;
  }

  .heading-secondary {
    font-size: 3.6rem;
  }

  .heading-tertiary {
    font-size: 2.4rem;
  }

  .header {
    padding: 0 3.2rem;
  }

  .main-nav-list {
    gap: 3.2rem;
  }

  .hero {
    gap: 4.8rem;
  }

  .testimonials-container {
    padding: 9.6rem 3.2rem;
  }
}

/* 1264px */
@media (max-width: 79em) {
  .btn-mobile-nav {
    display: block;
    z-index: 9999;
  }

  .main-nav {
    background-color: rgba(255, 255, 255, 0.9);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(10px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform: translateX(100%);

    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in;

    /* Hide navigation */
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  #search-form {
    display: none;
  }

  .search-mobile {
    display: inline-block;
  }

  .nav-open .main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
  }

  .nav-open .icon-mobile-nav[name='close-outline'] {
    display: block;
  }

  .nav-open .icon-mobile-nav[name='menu-outline'] {
    display: none;
  }

  .main-nav-list {
    flex-direction: column;
    gap: 4.8rem;
  }

  .main-nav-link:link,
  .main-nav-link:visited {
    font-size: 3rem;
    max-width: fit-content;
  }
}

/* 1024px */
@media (max-width: 66em) {
  .grid--3-cols,
  .grid--4-cols {
    grid-template-columns: repeat(2, 1fr);
  }

  .product-heading {
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 5rem;
  }

  .product-container {
    column-gap: 5rem;
    justify-items: center;
  }

  .product-img-container {
    width: 35rem;
    height: 30rem;
  }

  .product-img-container picture,
  .product-img-container img {
    width: 100%;
    height: 100%;
    top: 1rem;
    left: 0rem;
  }
}

/* 944 px  */
@media (max-width: 59em) {
  html {
    font-size: 50%;
  }
  .hero {
    grid-template-columns: 1fr;
    padding: 0 8rem;
    gap: 6.4rem;
  }

  .hero-text-box,
  .hero-img-box {
    text-align: center;
  }

  .hero-img {
    width: 60%;
  }

  .delivered-nests {
    height: 2.4rem;
  }

  .section-testimonials {
    grid-template-columns: 1fr;
  }

  .gallery {
    grid-template-columns: repeat(6, 1fr);
  }
}

/* 704px */
@media (max-width: 44em) {
  .grid--3-cols,
  .grid--4-cols {
    grid-template-columns: repeat(2, 1fr);
  }

  .heading-secondary {
    margin-bottom: 4.8rem;
  }

  .section-hero {
    padding-bottom: 4rem;
  }

  .product-heading {
    font-size: 3.8rem;
  }

  .product-img-container {
    width: 34rem;
    height: 32rem;
  }

  .delivered-nests {
    flex-direction: column;
  }

  .hero-img-box {
    margin-top: 8rem;
  }

  .footer {
    padding-top: 5rem;
  }

  .grid--footer {
    grid-template-columns: repeat(6, 1fr);
  }

  .logo-col,
  .address-col {
    grid-column: span 3;
  }

  .nav-col {
    grid-row: 1;
    grid-column: span 2;
    margin-bottom: 3.2rem;
  }
}

/* 544px phones */
@media (max-width: 34em) {
  .grid {
    row-gap: 4.8rem;
  }

  .grid--2-cols,
  .grid--3-cols,
  .grid--4-cols {
    grid-template-columns: 1fr;
  }

  .btn,
  .btn:link,
  .btn:visited {
    padding: 2rem 1.6rem;
  }

  .section-hero {
    padding: 2.4rem 0 6.4rem 0;
  }

  .hero {
    padding: 0 3.2rem;
  }

  .hero-img {
    width: 80%;
  }

  .logo {
    height: 24rem !important;
  }

  .why-img-box:nth-child(2) {
    grid-row: 1;
  }

  .why-img-box:nth-child(6) {
    grid-row: 5;
  }

  .why-img-box {
    transform: translateY(2.4rem);
  }

  .testimonials {
    grid-template-columns: 1fr;
  }

  .gallery {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.2rem;
  }

  .logo-flex {
    /* justify-content:  !important; */
    gap: 1rem !important;
  }

  .logo-flex span {
    font-size: 0.8rem;
  }

  .icon-mobile-nav {
    height: 3rem;
    width: 3rem;
    color: #333;
  }
  .grid--footer {
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    row-gap: 5rem !important;
    column-gap: 3rem !important;
  }

  .logo-col {
    grid-row: 5 / 6;
    grid-column: 1 / 2;
  }

  .address-col {
    grid-row: 1 / 2;
    grid-column: 1 / 1;
  }

  #map {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
  }
  .contacts {
    display: flex;
    flex-direction: column;
  }

  .company-col {
    grid-row: 4 / 5;
    grid-column: 1 / 2;
  }

  .resource-col {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
  }

  .footer-contact {
    display: flex;
    flex-direction: column;
  }
}
