@media (max-width: 107em) {
  .zalo-widget, .phone-widget {
    width: 5rem;
  }

  .widget-cantact-number {
    display: none !important;
  }
}

@media (max-width: 84em) {
  .hero {
    max-width: 120rem;
  }

  .heading-primary {
    font-size: 4.4rem;
  }

  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }

  .product-img-container {
    width: 34rem;
    height: 34rem;
  }
}

@media (max-width: 79em) {
  html {
    font-size: 56.25%;
  }

  .grid {
    gap: 6.4rem 4.8rem;
  }

  .heading-secondary {
    font-size: 3.6rem;
  }

  .heading-tertiary {
    font-size: 2.4rem;
  }

  .header {
    padding: 0 3.2rem;
  }

  .main-nav-list {
    gap: 3.2rem;
  }

  .hero {
    gap: 4.8rem;
  }

  .testimonials-container {
    padding: 9.6rem 3.2rem;
  }

  .btn-mobile-nav {
    z-index: 9999;
    display: block;
  }

  .main-nav {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    background-color: #ffffffe6;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    transition: all .5s ease-in;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(100%);
  }

  #search-form {
    display: none;
  }

  .search-mobile {
    display: inline-block;
  }

  .nav-open .main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
  }

  .nav-open .icon-mobile-nav[name="close-outline"] {
    display: block;
  }

  .nav-open .icon-mobile-nav[name="menu-outline"] {
    display: none;
  }

  .main-nav-list {
    flex-direction: column;
    gap: 4.8rem;
  }

  .main-nav-link:link, .main-nav-link:visited {
    max-width: -moz-fit-content;
    max-width: fit-content;
    font-size: 3rem;
  }
}

@media (max-width: 66em) {
  .grid--3-cols, .grid--4-cols {
    grid-template-columns: repeat(2, 1fr);
  }

  .product-heading {
    place-content: center;
    font-size: 5rem;
    display: flex;
  }

  .product-container {
    justify-items: center;
    column-gap: 5rem;
  }

  .product-img-container {
    width: 35rem;
    height: 30rem;
  }

  .product-img-container picture, .product-img-container img {
    width: 100%;
    height: 100%;
    top: 1rem;
    left: 0;
  }
}

@media (max-width: 59em) {
  html {
    font-size: 50%;
  }

  .hero {
    grid-template-columns: 1fr;
    gap: 6.4rem;
    padding: 0 8rem;
  }

  .hero-text-box, .hero-img-box {
    text-align: center;
  }

  .hero-img {
    width: 60%;
  }

  .delivered-nests {
    height: 2.4rem;
  }

  .section-testimonials {
    grid-template-columns: 1fr;
  }

  .gallery {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (max-width: 44em) {
  .grid--3-cols, .grid--4-cols {
    grid-template-columns: repeat(2, 1fr);
  }

  .heading-secondary {
    margin-bottom: 4.8rem;
  }

  .section-hero {
    padding-bottom: 4rem;
  }

  .product-heading {
    font-size: 3.8rem;
  }

  .product-img-container {
    width: 34rem;
    height: 32rem;
  }

  .delivered-nests {
    flex-direction: column;
  }

  .hero-img-box {
    margin-top: 8rem;
  }

  .footer {
    padding-top: 5rem;
  }

  .grid--footer {
    grid-template-columns: repeat(6, 1fr);
  }

  .logo-col, .address-col {
    grid-column: span 3;
  }

  .nav-col {
    grid-area: 1 / span 2;
    margin-bottom: 3.2rem;
  }
}

@media (max-width: 34em) {
  .grid {
    row-gap: 4.8rem;
  }

  .grid--2-cols, .grid--3-cols, .grid--4-cols {
    grid-template-columns: 1fr;
  }

  .btn, .btn:link, .btn:visited {
    padding: 2rem 1.6rem;
  }

  .section-hero {
    padding: 2.4rem 0 6.4rem;
  }

  .hero {
    padding: 0 3.2rem;
  }

  .hero-img {
    width: 80%;
  }

  .logo {
    height: 24rem !important;
  }

  .why-img-box:nth-child(2) {
    grid-row: 1;
  }

  .why-img-box:nth-child(6) {
    grid-row: 5;
  }

  .why-img-box {
    transform: translateY(2.4rem);
  }

  .testimonials {
    grid-template-columns: 1fr;
  }

  .gallery {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.2rem;
  }

  .logo-flex {
    gap: 1rem !important;
  }

  .logo-flex span {
    font-size: .8rem;
  }

  .icon-mobile-nav {
    color: #333;
    width: 3rem;
    height: 3rem;
  }

  .grid--footer {
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr !important;
    gap: 5rem 3rem !important;
  }

  .logo-col {
    grid-area: 5 / 1 / 6 / 2;
  }

  .address-col {
    grid-area: 1 / 1 / 2 / 1;
  }

  #map {
    grid-area: 2 / 1 / 3 / 3;
  }

  .contacts {
    flex-direction: column;
    display: flex;
  }

  .company-col {
    grid-area: 4 / 1 / 5 / 2;
  }

  .resource-col {
    grid-area: 3 / 1 / 4 / 2;
  }

  .footer-contact {
    flex-direction: column;
    display: flex;
  }
}

/*# sourceMappingURL=index.81c5e300.css.map */
